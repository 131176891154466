import React, { useState } from 'react';
import { Container, TextField, Button, CircularProgress, Snackbar, Alert, Typography } from '@mui/material';
import logo from './assets/logo.jpg';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';

const CodeRequestForm = () => {
    const [email, setEmail] = useState('');
    const [chamberSerialNumber, setChamberSerialNumber] = useState('');
    const [chamberVersionNumber, setChamberVersionNumber] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const validateEmail = (value) => {
        // Use a simple regex for email validation
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    };

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setEmailValid(true);
    };

    const handleEmailBlur = () => {
        setEmailValid(validateEmail(email));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!emailValid) {
            return;
        }

        setLoading(true);

        try {
            // const response = await axios.post('http://127.0.0.1:5001/aralab-service/us-central1/submit', {
            const response = await axios.post('https://us-central1-aralab-service.cloudfunctions.net/submit', {
                email,
                chamberSerialNumber,
                chamberVersionNumber,
            });
            console.log('Server response:', response.data);
        } catch (error) {
            console.error('Error submitting:', error);
        } finally {
            setLoading(false);
            setShowSuccessMessage(true);
            setEmail('');
            setChamberSerialNumber('');
            setChamberVersionNumber('');
        }
    };

    const handleSnackbarClose = () => {
        setShowSuccessMessage(false);
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <img src={logo} alt="Logo" style={{ width: '300px' }} />
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Chamber Serial Number"
                    variant="outlined"
                    margin="normal"
                    required
                    type="number"
                    startAdornment={<LockIcon />}
                    value={chamberSerialNumber}
                    onChange={(e) => setChamberSerialNumber(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="ClimaPlus Version Number"
                    variant="outlined"
                    margin="normal"
                    required
                    type="number"
                    startAdornment={<LockIcon />}
                    value={chamberVersionNumber}
                    onChange={(e) => setChamberVersionNumber(e.target.value)}
                    // helperText={'9xxx'}
                />
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    required
                    type="email"
                    startAdornment={<EmailIcon />}
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={!emailValid} // Apply error state based on email validity
                    helperText={!emailValid ? 'Invalid email format' : 'Your email registered on our Service DB'}
                />
                <Container disableGutters maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '15px' }}>
                    {loading ? (
                        <CircularProgress style={{ marginTop: '20px' }} />
                    ) : (
                        <Button fullWidth variant="contained" color="primary" type="submit">
                            Request Code
                        </Button>
                    )}
                </Container>
            </form>
            <Snackbar
                open={showSuccessMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000} // Duration in milliseconds
                onClose={handleSnackbarClose}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    This is a success message!
                </Alert>
            </Snackbar>
        </Container >
    );
};

export default CodeRequestForm;
